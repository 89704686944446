<template>
  <div class="default-layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "DefaultLayout",
};
</script>

<style></style>
